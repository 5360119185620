/*
  A generic infinite scroll component, that mostly only cares about fetching the
  next page of results.

  ```
    <drb-infinite-scroll
      results-key="results"
      per-page="12"
    >
      // The component assumes the first page of results will be server rendered,
      // if so, add the first page results here.

      // Otherwise, if you want the component to start empty and fetch the first page,
      // set the `page` attribute to 0.
    </drb-infinite-scroll>
  ```

  An invisible element (called the `sentinel`) is placed at the bottom of the
  results area, and when it comes into view, the next page of results is fetched.

  Slots (optional overrides):
    `no-results` - when there are no results (page 1)
    `no-more-results` - when there are no more results (page > 1)
    `loading` - when the next page is being fetched

  Attributes:
    - `disabled` (Boolean) - disables the infinite scroll (default: false)
    - `page` (Number) - the starting page number (default: 1)
    - `per-page` (Number) - the number of results per page (default: 10)
    - `page-limit` (Number) - the maximum number of pages to fetch (default: null)
    - `offset` (Number) - the number of pixels from the bottom of the viewport to trigger the fetch (default: 500)
    - `results-key` (String) - the key in the JSON response that contains the results (leave blank if the results are at the root level)
    - `base-url` (String) - the URL to fetch the results from (default: current URL)
    - `scroll-container-selector` (String) - the selector for the intersection observer root element (only used if the scroll overflow is not on the body)

  Methods:
    - `reset(url: string)` - resets the component, clears the results, and fetches the first page from the given URL
*/

import {
  LitElement, unsafeCSS, html,
} from 'lit';
import { customElement, property, state, queryAssignedNodes } from 'lit/decorators.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import styles from './drb-infinite-scroll.scss?inline';
import { onNextRepaint } from '~/shared/utils/animation';

@customElement('drb-infinite-scroll')
class DrbInfiniteScroll extends LitElement {
  static styles = unsafeCSS(styles);

  private _fetchAbortController = new AbortController();

  private _sentinelElRef: Ref<HTMLElement> = createRef();

  private _sentinelObserver?: IntersectionObserver;

  private _listItemSeenObserver?: IntersectionObserver;

  @property({ type: Boolean })
  disabled = false;

  @property({ type: Number })
  page = 1;

  @property({ attribute: 'per-page', type: Number })
  perPage = 10;

  @property({ attribute: 'page-limit', type: Number })
  pageLimit = null;

  @property({ type: Number })
  offset = 500;

  @property({ attribute: 'results-key', type: String })
  resultsKey = '';

  @property({ attribute: 'base-url', type: String })
  baseUrl = document.location.toString();

  @property({ attribute: 'scroll-container-selector', type: String })
  scrollContainerSelector = '';

  @property({ type: Boolean, reflect: true })
  resetting = false;

  @state()
  fetching = false;

  @state()
  hasMore = true;

  connectedCallback() {
    super.connectedCallback();
  }

  private _fetchNextPage() {
    this.page++;
    this._fetchPage(this.page);
  }

  private async _fetchPage(page: number) {
    const signal = this._fetchAbortController.signal;

    const url = this.baseUrl.startsWith('/')
      ? new URL(window.location.origin + this.baseUrl)
      : new URL(this.baseUrl);

    url.searchParams.set('page', page.toString());
    url.searchParams.set('perPage', this.perPage.toString());

    this.fetching = true;

    try {
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        signal: this._fetchAbortController.signal
      });

      // if the response is not expected, throw an error
      if (!response.ok && response.status !== 404) throw new Error('Something went wrong');

      // parse results
      const data = await response.json();

      this.dispatchEvent(new CustomEvent('drb-infinite-scroll-fetched', {
        detail: {
          status: response.status,
          data,
        }
      }));

      // if the response is a 404, there are no more results
      if (response.status === 404) {
        this.hasMore = false;
        return;
      }

      this.hasMore = true;

      const resultsHtml = this.resultsKey ? data[this.resultsKey] : data;

      const parser = new DOMParser();
      const resultsDoc = parser.parseFromString(resultsHtml, 'text/html');

      // append the new results to the DOM (or replace the content if we're resetting the results)
      if (this.resetting) {
        this.insertAdjacentHTML('afterbegin', resultsDoc.body.innerHTML);
      } else {
        // remove any duplicate results that are already in the DOM
        Array.from(resultsDoc.body.children).forEach(resultEl => {
          const id = resultEl.getAttribute('data-id');
          if (id && this.querySelector(`[data-id="${id}"]`)) resultEl.remove();
        });

        this.insertAdjacentHTML('beforeend', resultsDoc.body.innerHTML);
      }
    } catch (error) {
      if (this.resetting) this.hasMore = false;
      console.error(error);
    } finally {
      if (signal.aborted) return;
      this.resetting = false;
      this.fetching = false;

      this.dispatchEvent(new CustomEvent('drb-infinite-scroll-updated', {
        bubbles: true,
        composed: true,
      }));
    }
  }

  // clear all results from the DOM but leaves the slots in place (e.g. no-results, no-more-results)
  private clearResults() {
    Array.from(this.children).forEach((child) => {
      if (!child.hasAttribute('slot')) child.remove();
    });
  }

  private _trackSeen(element) {
    this.dispatchEvent(new CustomEvent('drb-infinite-scroll-item-seen', {
      detail: { element },
    }));
  }

  private _updateSentinelObserver() {
    if (this.disabled || this.fetching || !this.hasMore || (this.pageLimit && this.page >= this.pageLimit)) {
      this._sentinelObserver.unobserve(this._sentinelElRef.value);
    } else {
      this._sentinelObserver.observe(this._sentinelElRef.value);
    }
  }

  firstUpdated() {
    // Get the scroll container if specified, otherwise use null (viewport)
    const scrollContainer = this.scrollContainerSelector
      ? document.querySelector(this.scrollContainerSelector)
      : null;

    this._sentinelObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) this._fetchNextPage();
      });
    }, {
      root: scrollContainer,
      rootMargin: `${this.offset}px`
    });

    this._listItemSeenObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) this._trackSeen(entry.target);
      });
    }, {threshold: 1});
  }

  updated() {
    this._updateSentinelObserver();
    this.querySelectorAll(':scope > [data-id]').forEach((element) => {
      // TODO: Unsure if doubling-up the observe of existing elements might cause multiple "seen!" events
      this._listItemSeenObserver.observe(element);
    })
  }

  reset(url) {
    this.baseUrl = url || this.baseUrl;
    this.page = 0;
    this.fetching = false;
    this.disabled = false;
    this.resetting = true;

    this._fetchAbortController.abort();
    this._fetchAbortController = new AbortController();

    this.clearResults();
    this._fetchNextPage();

    onNextRepaint(() => {
      this.dispatchEvent(new CustomEvent('drb-infinite-scroll-resetting'));
    });
  }

  render() {
    const showNoResults = !this.hasMore && this.page === 1 && !this.fetching;
    const showNoMoreResults = !this.hasMore && this.page !== 1 && !this.fetching;
    const showSkeletonLoading = this.resetting && this.page == 1 && this.fetching;
    const showLoading = this.fetching && !this.resetting;

    return html`
      <slot></slot>

      <slot name="skeleton-loading" ?hidden=${!showSkeletonLoading}>
        <div align="center" part="skeleton-loading">Loading...</div>
      </slot>

      <slot name="no-results" ?hidden=${!showNoResults}>
        <div align="center" part="no-results">No results found</div>
      </slot>

      <slot name="no-more-results" ?hidden=${!showNoMoreResults}>
        <div align="center" part="no-more-results">You've reached the end of the list</div>
      </slot>

      <slot name="loading" ?hidden=${!showLoading}>
        <div class="loading" part="loading">
          <span class="default-loader"></span>
        </div>
      </slot>

      <div ${ref(this._sentinelElRef)}></div>
    `;
  }
}

export { DrbInfiniteScroll };
