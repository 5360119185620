/*
  A select component that can be used as a single or multi-select dropdown.

  ```
    <drb-select name="favorite-colors" value="red" multiple searchable>
      <drb-option value="red">Red</drb-option>
      <drb-option value="blue">Blue</drb-option>
      <drb-option value="green">Green</drb-option>
    </drb-select>
  ```

  Attributes:
    - `name` (string) - the name of the dropdown, used for form-associated elements.
    - `value` (string) - the initialvalue of the dropdown.
    - `placeholder` (string) - the placeholder text when no value is selected.
    - `multiple` (boolean) - if present, the dropdown will allow multiple selections.
    - `clearable` (boolean) - if present, the dropdown will have a clear button when a value is selected.
    - `searchable` (boolean) - if present, the dropdown will allow searching/filtering of options.
    - `creatable` (boolean) - if present, the dropdown will allow creating new options.
    - `disabled` (boolean) - if present, the dropdown will be disabled.
    - `size` ('small' | 'large' | 'x-large') - the size of the dropdown.
    - `placement` (string) - the placement of the dropdown popover. (default: "bottom-end") https://shoelace.style/components/popup#placement
    - `strategy` ('absolute' | 'fixed') - the strategy of the dropdown popover. (default: "absolute")

  Methods:
    - `open()` - opens the dropdown.
    - `close()` - closes the dropdown.
*/

import Fuse from 'fuse.js'
import { LitElement, unsafeCSS, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import styles from './drb-select.scss?inline';
import closeIcon from '~/assets/icons/v2-close.svg?raw';
import { DrbOption } from '~/web-components/drb-option/drb-option';
import { DrbPopover } from '~/web-components/drb-popover/drb-popover';
import isEqual from 'lodash/isEqual';
import { DrbOptionGroup } from '../drb-option-group/drb-option-group';

@customElement('drb-select')
class DrbSelect extends LitElement {
  static styles = unsafeCSS(styles);
  form?: HTMLFormElement = this.closest('form');
  fuse: Fuse<DrbOption> | null = null;
  isMounted = false;
  selectedCount = 0;
  maxSearchWeight = 1;

  @query('[data-display-input]') displayInput: HTMLInputElement;
  @query('drb-popover') drbPopover: DrbPopover;

  @property({ attribute: 'active', reflect: true })
  isActive = false;

  @property()
  name = "";

  /**
   * The current value of the select, submitted as a name/value pair with form data. When `multiple` is enabled, the
   * value html attribute will be a pipe-delimited list of values based on the options selected, and the value property will
   * be an array. ** For this reason, values must not contain pipes `|`. **
   */
  @property({
    converter: {
      fromAttribute: (value: string) => value.split('|'),
      toAttribute: (value: string[]) => value.join('|')
    }
  })
  value: string | string[] = '';

  @property({ type: String })
  placeholder = "";

  @property({ type: Boolean })
  multiple = false;

  @property({ type: Boolean })
  clearable = false;

  @property({ type: Boolean })
  searchable = false;

  @property({ attribute: 'open-on-search-only', type: Boolean })
  openOnSearchOnly = false;

  @property({ type: Boolean })
  creatable = false;

  @property({ type: Boolean })
  disabled = false;

  @property({ reflect: true })
  size: 'small' | 'large' | 'x-large' = 'small';

  @property({ attribute: 'placement', type: String })
  placement = "bottom-end";

  @property({ type: String })
  strategy: 'absolute' | 'fixed' = 'absolute';

  @state() displayLabel = '';
  @state() currentOption: DrbOption;
  @state() selectedOptions: DrbOption[] = [];

  connectedCallback() {
    super.connectedCallback();

    // Setup proper `formData`. When `multiple` is true, the formData will include multiple key/value pairs (like checkboxes)
    // When `multiple` is false, the formData will include a single key/value pair.
    if (this.form && this.name) {
      this.form.addEventListener('formdata', ({ formData }) => {
        if (Array.isArray(this.value)) {
          this.value.forEach(val => {
            formData.append(this.name, val.toString());
          });
        } else {
          formData.append(this.name, this.value.toString());
        }
      });
    }

    // close when clicking outside of it
    document.addEventListener('click', (e) => {
      const path = e.composedPath();

      if (this && !path.includes(this)) {
        this.close();
      }
    });

    // close when focusing out of the select
    document.addEventListener('focusin', (e) => {
      const path = e.composedPath();
      if (this && !path.includes(this)) {
        this.close();
      }
    });

    // close when pressing escape
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isActive) {
        e.preventDefault();
        e.stopPropagation();
        this.close();
      }
    });

    document.addEventListener('keydown', (e) => {
      const path = e.composedPath();
      if (this && !path.includes(this)) return;

      // update the current option when navigating via the keyboard arrows
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        if (!this.isActive) return;

        e.stopPropagation();
        e.preventDefault();

        const selectableOptions = this.getAllSelectableOptions();
        const currentIndex = selectableOptions.indexOf(this.currentOption);

        const indexModifier = e.key === 'ArrowDown' ? 1 : -1;
        let newIndex = currentIndex + indexModifier;

        // handle looping the list when going past the last/first option
        if (newIndex >= selectableOptions.length) {
          newIndex = 0;
        } else if (newIndex < 0) {
          newIndex = selectableOptions.length - 1;
        }

        this.setCurrentOption(selectableOptions[newIndex]);

        // scroll the current option into view
        this.currentOption?.scrollIntoView({ block: 'nearest' });
      };

      // handle enter and space keypresses
      if (e.key === 'Enter' || (e.key === ' ' && (!this.searchable || (this.searchable && !this.displayInput.value)))) {
        e.preventDefault();
        e.stopImmediatePropagation();

        // If it's not open, open it
        if (!this.isActive) {
          this.open();
          return;
        }

        // If it is open, update the value based on the current selection
        if (!this.currentOption || this.currentOption.disabled) return;

        if (this.multiple) {
          this.toggleOptionSelection(this.currentOption);
        } else {
          this.setSelectedOptions(this.currentOption);
        }
      }

      // remove the last selected option when pressing backspace
      if (e.key === 'Backspace') {
        if (!this.multiple || !this.selectedOptions.length || this.displayInput.value || this.shadowRoot?.activeElement !== this.displayInput) return;
        e.preventDefault();
        e.stopImmediatePropagation();

        this.toggleOptionSelection(this.selectedOptions[this.selectedOptions.length - 1], false);
      }
    })
  }

  // open/close select on click
  private handleSelectMouseDown(event: MouseEvent) {
    const path = event.composedPath();
    const isClickableElement = path.some(el => el instanceof Element && el.tagName.toLowerCase() === 'button');
    const isSearchableInput = this.searchable && path.some(el => el === this.displayInput);

    // ignore disabled controls, clicks on remove/clear buttons
    if (this.disabled || isClickableElement) {
      return;
    }

    // if the select is searchable, clicking on the input should open the select if it's not already open
    if (isSearchableInput) {
      if (!this.isActive) this.open();
      return;
    }

    // otherwise, toggle the select open/closed
    if (this.isActive) {
      this.close();
    } else {
      event.preventDefault(); // prevent the input from losing focus
      this.open();
    }
  }

  // gets an array of all <drb-option> elements
  private getAllOptions() {
    return Array.from(this.querySelectorAll<DrbOption>('drb-option'));
  }

  // gets an array of all <drb-option> elements that are valid to be searchable
  private getAllSearchableOptions() {
    return Array.from(this.querySelectorAll<DrbOption>('drb-option:not([user-created]):not([creatable-placeholder])'));
  }

  // gets an array of all <drb-option> elements that are valid to be selected, sorted by their list order
  private getAllSelectableOptions(sortByOrder = true) {
    const selectableOptions = Array.from(this.querySelectorAll<DrbOption>('drb-option:not([hidden]):not([disabled]):not([user-created])'));
    return sortByOrder ? selectableOptions.sort((a, b) => a.listOrder - b.listOrder) : selectableOptions;
  }

  // sets the current option, which is the option the user is currently interacting with (e.g. via keyboard or hover). only one
  // option may be "current" at a time.
  private setCurrentOption(option: DrbOption | null) {
    const allOptions = this.getAllOptions();

    // clear selection
    allOptions.forEach(el => {
      el.current = false;
    });

    // select the target option
    if (option) option.current = true;
    this.currentOption = option;
  }

  // each time an option is selected, it is assigned an incremented `selectedOrder` value.
  // this ensures the last selected option will appear last in the selected tags list (if multiple)
  private getNextSelectedOrder() {
    return this.selectedCount++;
  }

  // sets the selected option(s)
  private setSelectedOptions(option: DrbOption | DrbOption[]) {
    const allOptions = this.getAllOptions();
    const newSelectedOptions = Array.isArray(option) ? option : [option];

    // clear existing selection
    allOptions.forEach(el => (el.selected = false));

    // set the new selection
    if (newSelectedOptions.length) {
      newSelectedOptions.forEach(el => {
        el.selected = true;
        el.selectedOrder = this.getNextSelectedOrder();
      });
    }

    // update selection, value, and display input
    this.selectionChanged();
  }

  // toggles an option's selected state
  private toggleOptionSelection(option: DrbOption, force?: boolean) {
    if (force === true || force === false) {
      option.selected = force;
    } else {
      option.selected = !option.selected;
    }

    option.selectedOrder = this.getNextSelectedOrder();

    // update selection, value, and display input
    this.selectionChanged();
  }

  // called whenever the selection changes. updates the selected options cache, the current
  // value, and the display value
  private selectionChanged() {
    const options = this.getAllOptions();

    // update selected options cache
    this.selectedOptions = options.filter(el => el.selected).sort((a, b) => a.selectedOrder - b.selectedOrder);

    if (this.multiple) {
      this.value = this.selectedOptions.map(el => el.value);

      if (this.searchable) {
        // reset the display input
        if (this.displayInput.value) this.filterOptions('');
        this.displayInput.value = '';
        if (this.isActive) this.displayInput.focus({ preventScroll: true });
        if (this.openOnSearchOnly) this.close();
      }
    } else {
      const selectedOption = this.selectedOptions[0];
      this.value = selectedOption?.value ?? '';
      this.displayLabel = selectedOption?.label ?? '';
      this.displayInput.value = this.displayLabel;
      this.close();
    }

    this.updateDisplayInputSize();
  }

  private handleOptionClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const option: DrbOption = target.closest('drb-option');

    // prevent input from losing focus
    event.preventDefault();

    if (!option || option.disabled) return;

    if (this.multiple) {
      this.toggleOptionSelection(option);
    } else {
      this.setSelectedOptions(option);
    }
  }

  private handleOptionMouseOver(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const option: DrbOption = target.closest('drb-option');

    if (!option || option.disabled) return;

    this.setCurrentOption(option);
  }

  private handleDisplayInput(e: InputEvent) {
    if (!this.searchable) return;

    e.preventDefault();
    e.stopPropagation();

    this.updateDisplayInputSize();

    // if `openOnSearchOnly` is enabled, only open the select when the search input has a value
    if (this.openOnSearchOnly && !this.displayInput.value) {
      this.close();
      return;
    }

    this.open();
    this.filterOptions(this.displayInput.value);
  }

  // if the consumer updates the drb-options html in the default slot, we need to update the selected options and search index
  public handleDefaultSlotChange() {
    this.updateSelectedOptions();
    this.updateFuzzySearchIndex();
  }

  private buildOption(val: string, label: string) {
    const newOption = <DrbOption>document.createElement('drb-option');
    newOption.value = val;
    newOption.label = label;
    newOption.innerHTML = label;
    return newOption;
  }

  private initUserCreatedOptions() {
    const allOptions = this.getAllOptions();
    const value = Array.isArray(this.value) ? this.value : [this.value];

    // create new options for any values that don't already exist (and mark them as `user-created`)
    value.forEach(val => {
      if (val && !allOptions.some(option => option.value === val)) {
        const newOption = this.buildOption(val, val);
        newOption.selected = true;
        newOption.setAttribute('user-created', '');
        this.appendChild(newOption);
      }
    });

    // create a placeholder option used to create new options (e.g. `Add "My new option"`)
    if (this.creatable) {
      const creatablePlaceholderOption = this.buildOption('', '');
      creatablePlaceholderOption.setAttribute('creatable-placeholder', '');
      creatablePlaceholderOption.setAttribute('hidden', '');
      this.appendChild(creatablePlaceholderOption);
    }
  }

  // syncs selected options with the value
  private updateSelectedOptions() {
    // if the `creatable-placeholder` option exists and is selected, build a new option instead and de-select the placeholder.
    // (so we can keep using the placeholder for future user-created options)
    const creatablePlaceholderOption = this.querySelector<DrbOption>('drb-option[creatable-placeholder]');

    if (creatablePlaceholderOption?.selected) {
      // build new option and append it
      const newOption = this.buildOption(creatablePlaceholderOption.value, creatablePlaceholderOption.value);
      newOption.selected = true;
      newOption.selectedOrder = this.getNextSelectedOrder();
      newOption.setAttribute('user-created', '');
      this.appendChild(newOption);

      // reset the placeholder
      creatablePlaceholderOption.selected = false;
      creatablePlaceholderOption.value = '';
    }

    // update the selected options based on the value
    const newSelectedOptions: DrbOption[] = [];
    const allOptions = this.getAllOptions();
    const value = Array.isArray(this.value) ? this.value : [this.value];

    allOptions.forEach(option => {
      if (option.hasAttribute('user-created') && !value.includes(option.value)) {
        // cleanup any orphaned user-created options that are no longer needed (so the user can re-create them after removing them)
        option.remove();
      } else if (option.value && value.includes(option.value)) {
        newSelectedOptions.push(option);
      }
    });

    // select the options that match the value and sort them based on the order they were selected
    this.setSelectedOptions(newSelectedOptions.sort((a, b) => a.selectedOrder - b.selectedOrder));
  }

  // use fuse.js to create a fuzzy search index
  private updateFuzzySearchIndex() {
    const options = this.getAllSearchableOptions();
    this.maxSearchWeight = Math.max(...options.map(option => option.searchWeight));

    this.fuse = new Fuse<DrbOption>(options, {
      keys: [
        'label',
        {
          name: 'optionGroupLabel',
          weight: 0.4
        },
      ],
      threshold: 0.2,
      includeScore: true
    });
  }

  // filter the options based on the search query (or reset the options if no query is provided)
  private filterOptions(query) {
    if (!this.fuse) this.updateFuzzySearchIndex();
    const options = this.getAllOptions();
    const creatablePlaceholderOption = this.querySelector<DrbOption>('drb-option[creatable-placeholder]');

    if (query) {
      const results = this.fuse.search(query);

      results.sort((a, b) => {
        // use the options `searchWeight` to slightly favor options that are more popular
        a.score -= (a.item.searchWeight / this.maxSearchWeight) * 0.15;
        b.score -= (b.item.searchWeight / this.maxSearchWeight) * 0.15;
        return a.score - b.score;
      });

      const matchedOptions = results.map(result => result.item);

      // if the creatable-placeholder option exists and the query doesn't match any existing options, update and show the placeholder
      if (creatablePlaceholderOption && !options.some(option => option !== creatablePlaceholderOption && option.value === query)) {
        creatablePlaceholderOption.label = query;
        creatablePlaceholderOption.value = query;
        creatablePlaceholderOption.innerHTML = `<span class="font-weight-600">Create "${query}"</span>`;
        creatablePlaceholderOption.removeAttribute('hidden');
        matchedOptions.push(creatablePlaceholderOption);
      }

      // reset option groups (order last and hide them initially)
      const optionGroups = Array.from(this.querySelectorAll<DrbOptionGroup>('drb-option-group'));

      optionGroups.forEach(group => {
        group.listOrder = Infinity;
        group.setAttribute('hidden', '');
      });

      // update the options based on the search results
      options.forEach(option => {
        const matchedIndex = matchedOptions.indexOf(option);
        const isMatch = matchedIndex !== -1;

        // hide the option if it's not in the search results
        option.toggleAttribute('hidden', !isMatch);

        // set the order of the option based on its position in the search results
        option.listOrder = isMatch ? matchedIndex + 1 : 0;

        if (option.optionGroup && isMatch) {
          // set the group list order based on the highest ranking matched options
          option.optionGroup.listOrder = Math.min(option.listOrder, option.optionGroup.listOrder);

          // show the option group if it contains a matching option
          option.optionGroup.removeAttribute('hidden');
        }
      });

      this.setCurrentOption(matchedOptions[0]);
    } else {
      // show all options and reset order
      options.forEach(option => {
        option.removeAttribute('hidden');
        option.listOrder = 0;

        if (option.optionGroup) {
          option.optionGroup.listOrder = 0;
          option.optionGroup.removeAttribute('hidden');
        }
      });

      // hide the creatable-placeholder option (if it exists)
      creatablePlaceholderOption?.toggleAttribute('hidden', true);

      this.setCurrentOption(options[0]);
    }

    // scroll to the top of the list
    setTimeout(() => {
      this.drbPopover?.scrollContainerTo({ top: 0 });
    }, 0);
  }

  // sets the display input width based on the current value.
  // this keeps the input as small as possible which creates the ideal flex-wrap behavior for tags
  private updateDisplayInputSize() {
    if (!this.displayInput) return;

    const targetWidth = this.value.length ? `${this.displayInput.value.length}ch` : '100%';
    this.displayInput.style.setProperty('--display-input-width', targetWidth);
  }

  async firstUpdated() {
    this.updateDisplayInputSize();
    this.initUserCreatedOptions();
    this.updateSelectedOptions();
    await this.updateComplete;
    this.isMounted = true;
  }

  updated(changedProperties: Map<string, any>) {
    if (!this.isMounted) return;

    if (changedProperties.has('value')) {
      const hasChanged = !isEqual(changedProperties.get('value'), this.value);
      if (!hasChanged) return;

      // sync the selected options with the value if changed externally (e.g. consumer updates the value property)
      this.updateSelectedOptions();

      // notify any listeners that the select value has changed
      this.dispatchEvent(new CustomEvent('change', {
        bubbles: true,
        composed: true
      }));

      this.dispatchEvent(new CustomEvent('input', {
        bubbles: true,
        composed: true
      }));
    }
  }

  close() {
    this.isActive = false;
    this.displayInput.value = this.displayLabel;
  }

  open() {
    if (this.isActive || this.disabled) return;
    this.displayInput.focus({ preventScroll: true });

    if (this.openOnSearchOnly && !this.displayInput.value) return;

    // reset the current option
    this.filterOptions('');
    this.setCurrentOption(this.selectedOptions.find(option => !option.hasAttribute('user-created')) || this.getAllSelectableOptions()[0]);

    this.isActive = true;

    setTimeout(() => {
      this.currentOption?.scrollIntoView({ block: 'nearest' });
    }, 0);
  }

  protected get tags() {
    return this.selectedOptions.map((option, index) => {
      return html`
        <div class="select__tag">
          ${option.label}

          <button
            class="select__clear-btn"
            type="button"
            tabindex="-1"
            @click=${() => this.toggleOptionSelection(option, false)}
          >
            ${unsafeHTML(closeIcon)}
          </button>
        </div>
      `;
    });
  }

  render() {
    const showClearBtn = this.clearable && !this.disabled && this.value.length > 0;

    return html`
      <drb-popover
        active="${this.isActive || nothing}"
        strategy="${this.strategy}"
        distance="8"
        placement="${this.placement}"
        sync="width"
        @mouseover="${this.handleOptionMouseOver}"
      >
        <div
          class=${classMap({
            select: true,
            'select--active': this.isActive,
            'select--disabled': this.disabled,
            'select--multiple': this.multiple,
            'select--searchable': this.searchable,
            'select--hide-arrow': this.openOnSearchOnly,
          })}
          @mousedown=${this.handleSelectMouseDown}
        >
          <slot part="prefix" name="prefix" class="select__prefix"></slot>

          <div class="select__value-container">
            ${this.multiple && this.selectedOptions.length ? html`<div class="select__tags">${this.tags}</div>` : ''}

            <input
              class="select__input"
              data-display-input
              type="text"
              autocomplete="off"
              placeholder=${this.value.length ? '' : this.placeholder}
              autocorrect="off"
              spellcheck="false"
              ?readonly=${!this.searchable || this.disabled}
              .value=${this.displayLabel}
              tabindex="${this.disabled ? '-1' : '0'}"
              size="1"
              @focus="${() => {
                if (this.searchable) this.open();
                this.displayInput.setSelectionRange(this.displayInput.value.length, this.displayInput.value.length);
              }}"
              @input="${this.handleDisplayInput}"
            />
          </div>

          ${showClearBtn
            ? html`
                <button
                  class="select__clear-btn"
                  type="button"
                  aria-label="Clear"
                  tabindex="-1"
                  @click=${() => this.setSelectedOptions([])}
                >
                  ${unsafeHTML(closeIcon)}
                </button>
              `
            : ''}
        </div>

        <div
          class="option-list"
          slot="popover-content"
          @mousedown="${this.handleOptionClick}"
          @slotchange=${this.handleDefaultSlotChange}
        >
          <slot></slot>
        </div>
      </drb-popover>
    `;
  }
}

export { DrbSelect };
